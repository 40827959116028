.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--text-primary);
  background-color: var(--background);
  font-size: large;
  font-weight: bold;
  transition: all .5s;
  overflow-x: hidden;
  min-height: 100vh;
}

.page-body {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  color: var(--text-primary);
  max-width: 1000px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  margin-top: 80px;
}

/* used to shade out background similar to how a dialogue works */
.black-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.select-input {
  text-align: left;
}

.debug {
  border: 1px solid red !important;
}


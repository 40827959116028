.add-subcsription-card {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 90%;
  max-width: 400px;
  min-height: 450px;
  align-items: center;
}

.add-subscription-title-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 20px 10px 20px !important;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--accent-1);
}

.add-subscription-title {
  color: var(--text-primary);
  font-family: 'Trebuchet MS', sans-serif;
  margin: 0px 0px 10px 20px !important;
}

.cancel-add-subscription-icon-button {
  transform: scale(1.5);
  color: var(--delete) !important;
  margin: 0px 20px 10px 0px !important;
  padding: 3px !important;
  transition: all 0.3s !important;

  &:hover {
    transform: scale(1.8);
  } 
}

.add-subscription-form-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px !important;
  justify-content: center;
  align-items: center;
}

.add-subscription-input {
  flex: 1;
  margin: 10px !important;
  min-width: 300px !important;
  max-width: 300px !important;
  min-height: 75px !important;
  max-height: 75px !important;
}

.add-subscription-subscribe-button {
  color: var(--add) !important;
  border: 3px solid var(--add) !important;
  transition: all 0.3s !important;

  &:hover {
    transform: scale(1.1);
  } 
}
.signup-card {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
  min-height: 480px;
  max-width: 800px;
  margin: 20px;
  padding: 0px 20px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.create-account-button {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  height: 50px;
  width: 225px;
  background: var(--accent-1) !important;
  color: var(--text-white) !important;
}

.logo {
  margin-bottom: 30px;
}

.action-error-message {
  color: var(--error);
  font-size: 12px;
  margin: 0px !important;
  padding-bottom: 15px;
}

.login-text {
  color: var(--text-primary);
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.signup-input {
  flex-grow: 1;
  flex: 1;
  margin: 0 10px 10px 10px !important;
  min-width: 300px !important;
  max-width: 400px !important;
}

.signup-input-tos {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 0 10px 10px 10px !important;
  min-width: 300px !important;
  max-width: 400px !important;
  align-items: center;
  justify-content: center;
}

.tos-text {
  font-size: 14px !important;
  width: 250px;
}

.tos-checkbox {
  margin-top: -8px !important;
  /* padding: 0px !important; */
}

.calendar-input {
  flex-grow: 1;
  flex:1;
  min-width: 300px !important;
  max-width: 400px !important;
  margin: 0 10px 10px 10px !important;
}

.title {
  margin: 10px 0px 20px 0px !important;
  color: var(--accent-1);
}

.signup-icon {
  margin-bottom: 40px;
  height: 50px;
}

.login-text {
  color: var(--text-primary);
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.login-text:hover {
  cursor: pointer;
}

.login-link {
  font-size: 15px !important;
  color: var(--text-link) !important;
  margin: 0px !important;
  padding: 0px !important;
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signup-circular-progress {
  color: var(--text-secondary) !important;
}

.password-text {
  color: var(--accent-1);
  font-size: 12px !important;
  font-weight: bold !important;
}
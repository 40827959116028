.toolbar {
  background-color: var(--background-secondary);
  color: var(--text-primary);
}

.signin-panel {
  display: flex;
  margin-left: auto;
  margin-right: -20px;
  flex-direction: row;
  align-items: center;
}

.user-card {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.title-image {
  margin-left: -10px;
  height: 40px;
  padding: 5px 0px;
}

.menu-icon {
  transform: scale(1.5);
}

.user-greeting {
  color: var(--accent-1);
  padding: 0px !important;
  font-size: 15px !important;
}

.header-circle-progress {
  color: var(--accent-1) !important;
}

.theme-icon-button {
  margin: 0px 20px 0px 5px !important;
}


.account-icon {
  color: var(--text-primary) !important;
}

.header-icon {
  color: var(--text-primary) !important;
  transform: scale(1.1)
}

.menu-header {
  &:hover {
    cursor: default;
  }
  
  height: 30px !important;
}

.account-menu-item {
  color: var(--text-primary) !important;
}

.header-title {
  color: var(--text-primary);
  text-decoration: none;
  font-size: 35px;
  font-weight: 200 !important;
  font-family: 'Trebuchet MS', sans-serif;
}

.header-title-icon {
  color: var(--accent-1);
  transform: scale(1.3);
}

.signup-button {
  background: var(--accent-1) !important;
  color: var(--text-white) !important;
  box-shadow: 0px 3px 5px var(--shadow);
  height: 30px;
  margin: 0px 10px !important;
}
.terms-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 480px;
  max-width: 800px;
  margin: 20px;
  padding: 0px 20px;
}

.terms-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify !important;
  width: 80%;
  margin-bottom: 40px;
}

.logo {
  margin-bottom: 30px;
}

.terms-title {
  font-size: 14;
  margin: 40px 0px 20px 0px !important;
  color: var(--text-primary);
}

.email-link {
  font-size: 15px !important;
  color: var(--text-link) !important;
  margin: 0px !important;
  padding: 0px !important;
}

.terms-icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin-bottom: 10px;
  margin-top: -20px;
}

.terms-icon {
  color: var(--accent-1);
  scale: 3.0
}

.effective-date {
  margin-top: 20px;
  margin-bottom: 80px !important;
}
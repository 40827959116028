.bluf-statement-background {
  background-image: url(../../../public/images/home-page/header-background-blue.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  width: 100%;
  position: absolute;
  z-index: 1;
  max-width: 800px;
  min-width: 300px;
  background-position: 50% 0;
}

.bluf-statement {
  display: flex;
  flex-direction: column;
  height: 500px;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
  margin-top: -40px;
}

.bluf-statement-card {
  display: flex;
  flex-direction: column;
  z-index: 2;
  align-items: center !important;
  justify-content: center !important;
  padding: 20px;
  min-width: 300px;
  width: 80vw;
  max-width: 500px;
  border-radius: 10px !important;

}

.bluf-text {
  z-index: 2;
  padding: 10px 20px;
  margin: 0px 20px;
}

.get-started {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  height: 50px;
  align-items: center;
  justify-content: center;
  background-color: var(--accent-1);
  padding: 10px 0px;
  max-width: 1000px;
  margin: auto;
}

.home-circle-progress {
  color: var(--white) !important;
}

.audio-demo {
  max-width: 1000px;
  min-width: 300px;
  width: 80%;
  border-top: 1px solid var(--accent-1);
  margin: 40px 0px 80px 0px;
}

.audio-button {
  background: var(--accent-1) !important;
  color: var(--text-white) !important;
  margin: 10px 20px !important;
  width: 150px;
  box-shadow: 0px 3px 5px var(--shadow);
}

.restart-button {
  background: var(--background-secondary) !important;
  color: var(--accent-1) !important;
  margin: 10px 20px !important;
  width: 150px;
  box-shadow: 0px 3px 5px var(--shadow);
}

.text-carousel {
  height: 350px;
  width: 460px;
  margin: 10px 0 100px 0;
  padding: 0px 500px !important;
}
.summary-box {
  margin: 0px 55px !important;
  width: 350px;
}

.summary-card {
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary) !important;
  color: var(--text-primary) !important;
  width: 100%;
  height: 400px;
  border-radius: 20px !important;
}

.summary-title {
  padding: 20px 0px 0px 0px;
  font-weight: bold !important;
}

.summary-text-box {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  font-weight: 900 !important;
  padding: 10px;
}

.summary-text {
  font-weight: bold !important;
  text-align: justify;
}

.contact-text {
  margin: 30px 0px 0px 0px !important;
}

.audio-title-no-webgl {
  margin: 40px !important;
}

.audio-icon-no-webgl {
    color: var(--accent-1);
    transform: scale(1.5);
}

.whatsapp-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1000px;
  min-width: 300px;
  width: 80%;
  border-top: 1px solid var(--accent-1);
  padding: 40px 0px;
  margin-bottom: 10px;
}

.whatsapp-example-image {
  height: 500px;
  width: 250px;
  padding: 0px 25px;
  margin: 20px 0px;
}

.whatsapp-info-subsection {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 300px;
  height: 500px;
  border: 1px solid var(--accent-1);
  margin: 20px 0px;
}

.whatsapp-info-title {
  padding-top: 20px;
  color: var(--accent-1);
  font-weight: bold !important;
}

.whatsapp-info-text {
  padding: 40px 20px;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  margin: 20px 0;
  max-width: 1000px;
  min-width: 300px;
  width: 80%;
  border-top: 1px solid var(--accent-1);
  border-bottom: 1px solid var(--accent-1);
}

.contact-button {
  background: var(--accent-1) !important;
  color: var(--text-white) !important;
  box-shadow: 0px 3px 5px var(--shadow);
  margin-bottom: 30px !important;
}

.contact-image {
  margin: 40px;
  height: 225px;
  width: 300px;
}

.quick-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1000px;
  min-width: 300px;
  width: 80%;
  margin-top: 40px;
  padding: 20px 0px;
}

.quick-link-1 {
  background: var(--accent-1) !important;
  color: var(--text-white) !important;
  font-weight: bold !important;
  box-shadow: 0px 3px 5px var(--shadow);
  padding: 20px !important;
}

.quick-link-2 {
  background: var(--background-secondary) !important;
  color: var(--text-primary) !important;
  font-weight: bold !important;
  box-shadow: 0px 3px 5px var(--shadow);
  padding: 20px !important;
}

.quick-link-2-icon {
  color: var(--accent-1);
  transform: scale(1.5);
  padding: 0px 10px 0px 5px;
}

.additional-info-heading {
  margin: 30px 0px !important;
}

.additional-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 50px 0px;
  margin: 0px 0px 20px 0px;
  max-width: 1000px;
  min-width: 300px;
  width: 80%;
  border-bottom: 1px solid var(--accent-1);
}

.additional-info-subsection {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 40px;
}

.privacy-button {
  background: var(--background-secondary) !important;
  color: var(--accent-1) !important;
  margin: 10px 20px !important;
  width: 200px;
  box-shadow: 0px 3px 5px var(--shadow);
}

.terms-button {
  background: var(--accent-1) !important;
  color: var(--text-white) !important;
  margin: 10px 20px !important;
  width: 200px;
  box-shadow: 0px 3px 5px var(--shadow);
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.business-heading {
  margin: 30px 0px 0px 0px !important;
}

.business-subheading {
  color: var(--accent-1);
  margin: 20px 0px 20px 0px !important;
}

.business-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 0px 0px;
  margin: 0px 0px 20px 0px;
  max-width: 1000px;
  min-width: 300px;
  width: 80%;
  border-top: 1px solid var(--accent-1);
}

.business-info-subsection {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 600px;
  margin: 20px 0px;
}

.business-info-text {
  padding: 40px 20px;
}

.business-info-image-1 {
  margin: 40px;
  height: 225px;
  width: 250px;
}

.business-info-image-2 {
  margin: 40px;
  height: 225px;
  width: 250px;
}

.business-info-divider {
  border-top: 4px solid var(--text-primary);
  max-width: 1000px;
  min-width: 300px;
  width: 90%;
}

.additional-info-image {
  margin: 40px;
  height: 200px;
  width: 200px;
}
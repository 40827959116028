.my-subscriptions-loading-box {
  margin: auto;
}

.load-my-subscriptions-circle-progress {
  color: var(--accent-1) !important;
  margin: auto;
  padding: 20px;
}

.my-subscriptions-box {
  display: flex;
  flex: 1;
}

.my-subscriptions-card {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 80vw;
  max-width: 800px;
  height: 60%;
  box-shadow: 3px 3px 3px var(--shadow);
  background-color: var(--background-table);
}

.my-subscriptions-title-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 20px 10px 15px !important;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--accent-1);
}

.my-subscriptions-grid-box {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
}

.my-subscriptions-title {
  color: var(--text-primary);
  font-family: 'Trebuchet MS', sans-serif;
}

.add-subscription-button {
  color: var(--add) !important;
  border: 3px solid var(--add) !important;
  transition: all 0.3s !important;
  box-shadow: 0px 3px 5px var(--shadow);
  margin: 0px 25px 5px 0px !important;

  &:hover {
    transform: scale(1.05);
  } 
}

.add-subscription-button-text {
  font-family: 'Trebuchet MS', sans-serif;
}

.add-subscription-button-icon {
  padding: 0px 10px 0px 0px;
}
:root {
  --background: #efeff3;
  --background-secondary: #ffffff;
  --background-table: #161616;
  --background-accent: #aaa6ff;
  --text-primary: #1e1e1e;
  --text-secondary: #eeeeee;
  --text-white: #eeeeee;
  --text-link: #1976d2;
  --accent-1: #3c599f;
  --button-primary: #3c599f;
  --button-secondary: #eeeeee;
  --accent-2: rgb(149, 16, 149);
  --error: red;
  --white: #ffffff;
  --shadow: #555555;
  --delete: #EE5144;
  --add: #1DA462;
  --level-1: #1DA462;
  --level-2: #4C8BF5;
  --level-3: #FFCD46;
  --level-4: #DD5144;
  --level-5: #810b00;
}
[data-theme='dark'] {
  --background: #282c34;
  --background-secondary: #1e1e1e;
  --background-accent: rgb(225, 135, 210);
  --text-primary: #eeeeee;
  --background-table: #ffffff;
  --text-secondary: #1e1e1e;
  --accent-1: #4e73ce;
  --button-primary: #181c23;
  --button-secondary: #4e73ce;
  --shadow: #000000;
}

.background {
  background-color: var(--background);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Override horrendous input coloring for autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
   -webkit-box-shadow: 0 0 0 30px var(--background-secondary) inset !important;
   -webkit-text-fill-color: var(--text-primary) !important;
}

/*  Override scrollbar look */
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  right: 0;
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 8px;
  width: 12px;
  height: 12px;
}
.login-card {
  display: flex;
  flex-direction: column;
  width: 400px;
  min-height: 380px;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 10px 10px 10px !important;
  padding: 0px 10px 10px 10px !important;
}

.login-button {
  margin: 10px !important;
  height: 50px;
  width: 80%;
  background: var(--accent-1) !important;
  color: var(--text-white) !important;
}

.action-error-message {
  color: var(--error);
  margin-bottom: 0px !important;
  padding-bottom: 15px;
}

.signup-text {
  color: var(--text-primary);
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.signup-text:hover {
  cursor: pointer;
}

.signup-link {
  font-size: 15px !important;
  color: var(--text-link) !important;
  margin: 0px !important;
  padding: 0px !important;
}

.login-input {
  width: 80%;
  margin-bottom: 10px !important;
}

.bullet {
  display: inline-block;
  margin: 0 2px;
  transform: scale(0.8);
}

.title {
  color: var(--text-primary) !important;
  font-size: 14;
  margin: 10px 10px 0px 10px !important;
  padding: 10px 10px 0px 10px !important;
}

.login-icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin-bottom: 10px;
  margin-top: -20px;
}

.login-icon {
  color: var(--accent-1);
  scale: 3.0
}

.login-circular-progress {
  color: var(--text-secondary) !important;
}